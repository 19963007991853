<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ `${$t("salesforce.Edit")}` }}</span>
          </v-card-title>

          <v-card-text class="text--primary">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("route") }} : {{ selected.route_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("Date") }} : {{ selected.worksessionDate }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("User") }} : {{ selected.user_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ this.$t("pos") + " " + this.$t("brands.name") }} :
                    {{ selected.pos_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("address") }} : {{ selected.address_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("chain") }} : {{ selected.chain_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("channel") }} : {{ selected.channel_name }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("brands.brands") }} : {{ selected.brand_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    objectivo PS : {{ selected.objectiveValue }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    punt total PS : {{ selected.posBrandPSCurrentVisit }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    objectivo Ventas : {{ selected.salesObjective }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">Ventas : {{ selected.salesTotalValue }}</h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    Quality Info : {{ selected.qualityInfo }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `${this.$t("Theoretical")} - ${this.$t("visit_type")}` }}
                    : {{ selected.originalVisitType_label }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ `real - ${this.$t("visit_type")}` }}
                    : {{ selected.realVisitType_label }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{
                      `${this.$t("approved")} by ${this.$t(
                        "salesforce.Parent"
                      )}`
                    }}
                  </h6>
                  <v-autocomplete
                    v-model="selected.approvalStatus"
                    :items="approval_status"
                    item-text="label"
                    item-value="value"
                    filled
                    :readonly="!selected.isChecking"
                    :required="selected.isChecking"
                    :rules="selected.isChecking ? requiredRules : []"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
              v-if="selected.isChecking"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("navbar.objectives") }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2"> </v-col>
          <v-col cols="12" sm="12" md="8" class="status_wrapper">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <b-card
                  bg-variant="dark"
                  v-bind:header="`Cumpl Rutero`"
                  class="text-center"
                >
                  <b-card-text>
                    {{ statistics_data.route_compliance_calced }}% vs
                    {{ statistics_data.route_compliance_fixed }}%
                  </b-card-text>
                </b-card>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <b-card
                  bg-variant="dark"
                  v-bind:header="`Perfect Store`"
                  class="text-center"
                >
                  <b-card-text>
                    {{ statistics_data.total_ps_points }} vs
                    {{ statistics_data.objectiveValue }}
                  </b-card-text>
                </b-card>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <b-card
                  bg-variant="dark"
                  v-bind:header="$t('brands.Sales')"
                  class="text-center"
                >
                  <b-card-text>
                    {{ statistics_data.salesTotalValue }}€ vs
                    {{ statistics_data.salesObjective }}€
                  </b-card-text>
                </b-card>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <b-card
                  bg-variant="dark"
                  v-bind:header="$t('Quality Info')"
                  class="text-center"
                >
                  <b-card-text>
                    {{ statistics_data.qualityInfoSum }}% vs
                    {{ statistics_data.qualityInfoFixed }}%
                  </b-card-text>
                </b-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="2"
            class="d-flex align-end justify-content-end"
          >
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefRouteCompliance"
              id="datatable_objectivos"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "SalesForceObjectives",
  data: function() {
    return {
      visitTypes: [],
      VISIT_TYPES: {},
      approval_status: [],
      filterlist_fetch_url: "salesforce/objectives/getfilterlist",
      filterable_col_list: [],
      filterCriteria: {
        route: {},
        date: {},
        user: {},
        pos: {},
        address_label: "",
        chain: {},
        channel: {},
        brand: {},
        objectiveValue: {},
        posBrandPSCurrentVisit: {},
        salesObjective: {},
        salesTotalValue: {},
        originalVisitType: {},
        realVisitType: {},
        approvalStatus: {},
        qualityInfo: {},
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,
      totaldata: 0,
      data: [],
      statistics_data: {},

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      isSaving: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isGPV",
      "isAdmin",
      "isSubAdmin",
      "user",
    ]),
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: "",
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={(value) =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_chain() {
      return {
        key: "chain",
        title: this.$t("chain"),
        field: "chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_channel() {
      return {
        key: "channel",
        title: this.$t("channel"),
        field: "channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brands"),
        field: "brand",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_objectiveValue() {
      return {
        key: "objectiveValue",
        title: `objectivo PS`,
        field: "objectiveValue",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["objectiveValue"]["from"]}
                  value={this.filterCriteria["objectiveValue"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["objectiveValue"]["to"]}
                  value={this.filterCriteria["objectiveValue"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "objectiveValue")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posBrandPSCurrentVisit() {
      return {
        key: "posBrandPSCurrentVisit",
        title: `punt total PS`,
        field: "posBrandPSCurrentVisit",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={
                    this.filterCriteria["posBrandPSCurrentVisit"]["from"]
                  }
                  value={this.filterCriteria["posBrandPSCurrentVisit"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posBrandPSCurrentVisit"]["to"]}
                  value={this.filterCriteria["posBrandPSCurrentVisit"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "posBrandPSCurrentVisit")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_salesObjective() {
      return {
        key: "salesObjective",
        title: `objectivo Ventas`,
        field: "salesObjective",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesObjective"]["from"]}
                  value={this.filterCriteria["salesObjective"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesObjective"]["to"]}
                  value={this.filterCriteria["salesObjective"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "salesObjective")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_salesTotalValue() {
      return {
        key: "salesTotalValue",
        title: `Ventas`,
        field: "salesTotalValue",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesTotalValue"]["from"]}
                  value={this.filterCriteria["salesTotalValue"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesTotalValue"]["to"]}
                  value={this.filterCriteria["salesTotalValue"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "salesTotalValue")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_originalVisitType() {
      return {
        key: "originalVisitType",
        title: `${this.$t("Theoretical")} - ${this.$t("visit_type")}`,
        field: "originalVisitType",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.originalVisitType_label}</span>;
        },
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableCheckboxesOthers
        //           v-model={this.filterCriteria["originalVisitType"]}
        //           dataList={this.visitTypes}
        //           filterCriteria={this.filterCriteria["originalVisitType"]}
        //           itemvalue="value"
        //           itemlabel="label"
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() =>
        //               this.searchCancel(closeFn, "originalVisitType")
        //             }
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_realVisitType() {
      return {
        key: "realVisitType",
        title: `real - ${this.$t("visit_type")}`,
        field: "realVisitType",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.realVisitType_label}</span>;
        },
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableCheckboxesOthers
        //           v-model={this.filterCriteria["realVisitType"]}
        //           dataList={this.visitTypes}
        //           filterCriteria={this.filterCriteria["realVisitType"]}
        //           itemvalue="value"
        //           itemlabel="label"
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "realVisitType")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_approvalStatus() {
      return {
        key: "approvalStatus",
        title: `${this.$t("approved")} by ${this.$t("salesforce.Parent")}`,
        field: "approvalStatus",
        align: "left",
        sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.approvalStatus_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["approvalStatus"]}
                  dataList={this.approval_status}
                  filterCriteria={this.filterCriteria["approvalStatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "approvalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_qualityInfo() {
      return {
        key: "qualityInfo",
        title: `Quality Info`,
        field: "qualityInfo",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.qualityInfoLabel}</span>;
        },
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["qualityInfo"]["from"]}
        //           value={this.filterCriteria["qualityInfo"]["from"]}
        //           placeholder="From - "
        //         />
        //         <br />
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["qualityInfo"]["to"]}
        //           value={this.filterCriteria["qualityInfo"]["to"]}
        //           placeholder="To - "
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "qualityInfo")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    columns() {
      return [
        this.col_route,
        this.col_date,
        this.col_userId,
        this.col_user,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_chain,
        this.col_channel,
        this.col_brand,
        this.col_objectiveValue, // objectivePS
        this.col_posBrandPSCurrentVisit, // puntTotalPS
        this.col_salesObjective, // sales
        this.col_salesTotalValue, // sales_objective
        this.col_originalVisitType,
        this.col_realVisitType,
        this.col_approvalStatus,
        this.col_qualityInfo, // qualityInfo
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        cancelFilterKey === "objectiveValue" ||
        cancelFilterKey === "posBrandPSCurrentVisit" ||
        cancelFilterKey === "salesObjective" ||
        cancelFilterKey === "date" ||
        cancelFilterKey === "salesTotalValue"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_objectivos"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (
            key === "objectiveValue" ||
            key === "posBrandPSCurrentVisit" ||
            key === "salesObjective" ||
            key === "date" ||
            key === "salesTotalValue"
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value,
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable_objectivos .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "salesforce/objectives";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.visitTypes = resp.visitTypes;
        this.approval_status = resp.approval_status;
        this.statistics_data = resp.statistics_data;
        this.VISIT_TYPES = resp.VISIT_TYPES;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "salesforce/objectives/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      console.log("createSelectedModel - ", item);
      let model = {
        id: _.get(item, "id"),
        approvalStatus: _.get(item, "approvalStatus"),
        isChecking: true,
      };
      if (item) {
        model = { ...item, ...model };
      }
      if (this.isAdmin || this.isSubAdmin) {
        model.isChecking = true;
      } else {
        if (model.userId !== this.user.id) {
          model.isChecking = true;
        } else {
          model.isChecking = false;
        }
      }
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        const { id, approvalStatus } = item;
        let body = { approvalStatus };
        if (id) {
          try {
            await ApiService.put(`salesforce/objectives/${id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    init() {
      this.filterable_col_list = Object.keys(this.filterCriteria);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth();
      let startEndDateOfMonth = this.getFirstAndLastDateOfMonth(
        currentYear,
        currentMonth + 1
      );
      this.filterCriteria.date = {
        from: startEndDateOfMonth.firstDate,
        to: startEndDateOfMonth.lastDate,
      };
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
